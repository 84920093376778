<template>
  <div class="auth-wrapper auth-v2">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-row class="auth-inner m-0">

        <!-- Brand logo-->
        <b-link class="brand-logo">
          <h2 class="brand-text brand-text-custom text-primary ml-1" style="font-size: 36px !important; margin-top: 0px !important;">
            Knox Connect
          </h2>
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-1 font-weight-bolder"
              title-tag="h2"
            >
              <!-- <img style="width: 20%;" src="@/assets/images/logo/e-tech-logo.png" alt="이테크 시스템"> -->
              Knox Connect
            </b-card-title>
            <b-card-text class="mb-2">
              계정에 로그인해주세요.
            </b-card-text>
            <!-- tab -->
            <b-tabs class="custom">
              <b-tab title="담당자" @click="item.userSe = 'GNR'">
              </b-tab>
              <b-tab title="슈퍼관리자" @click="item.userSe = 'USR'">
              </b-tab>
            </b-tabs>
            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="onSubmit"
              >
                <!-- id -->
                <b-form-group
                  label="아이디"
                  label-for="login-id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="이메일"
                    vid="id"
                    rules="required"
                  >
                    <b-form-input
                      id="아이디"
                      v-model="item.id"
                      :state="errors.length > 0 ? false:null"
                      name="login-id"
                      placeholder="아이디"
                      @keydown.enter.prevent="onSubmit"
                    />
                    <small class="text-danger" v-if="errors[0]">아이디를 입력해주세요.</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">비밀번호</label>
                    <!-- <b-link :to="{name:'auth-forgot-password'}">
                      <small>Forgot Password?</small>
                    </b-link> -->
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="비밀번호"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="item.password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="비밀번호"
                        @keydown.enter.prevent="onSubmit"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger" v-if="errors[0]">비밀번호를 입력해주세요.</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    아이디 저장
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  class="font-weight-bold"
                  :disabled="invalid"
                >
                  로그인
                </b-button>
              </b-form>
            </validation-observer>

            <!-- <b-card-text class="text-center mt-2">
              <span>처음이신가요 ? </span>
              <b-link :to="{name:'auth-register'}">
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text> -->

            <!-- divider -->
            <!-- <div class="divider my-2">
              <div class="divider-text">
                or
              </div>
            </div> -->

            <!-- social buttons -->
            <!-- <div class="auth-footer-btn d-flex justify-content-center">
              <b-button
                variant="facebook"
                href="javascript:void(0)"
              >
                <feather-icon icon="FacebookIcon" />
              </b-button>
              <b-button
                variant="twitter"
                href="javascript:void(0)"
              >
                <feather-icon icon="TwitterIcon" />
              </b-button>
              <b-button
                variant="google"
                href="javascript:void(0)"
              >
                <feather-icon icon="MailIcon" />
              </b-button>
              <b-button
                variant="github"
                href="javascript:void(0)"
              >
                <feather-icon icon="GithubIcon" />
              </b-button>
            </div> -->
          </b-col>
        </b-col>
      <!-- /Login-->
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip, BTabs, BTab, BOverlay
} from 'bootstrap-vue'
// import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
// import {mapState} from 'vuex'
import swal from 'sweetalert';

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
const qs = require('qs')
const loginVO = JSON.parse(sessionStorage.getItem('loginVO')) // 세션스토리지에서 로그인 정보 get

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BTab,
    BTabs,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: false,
      password: 'admin',
      userEmail: 'admin@demo.com',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      item: {
        userSe: 'GNR', // 기본 담당자 권한으로 SET
        id: '',
        password: ''
      },
      show: false,
      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    if (this.getCookie("id")) { // getCookie함수로 id라는 이름의 쿠키를 불러와서 있을경우
      this.item.id = this.getCookie("id"); //input 이름이 id인곳에 getCookie("id")값을 넣어줌
      this.status = true; // 체크는 체크됨으로
    }
    if(loginVO){
      // 로그인 정보 세션스토리지 삭제
      sessionStorage.removeItem('loginVO');
    }
  },
  methods: {
    deepCopy(obj){
      var copy;

      // Handle the 3 simple types, and null or undefined
      if (null == obj || "object" != typeof obj) return obj;

      // Handle Date
      if (obj instanceof Date) {
          copy = new Date();
          copy.setTime(obj.getTime());
          return copy;
      }

      // Handle Array
      if (obj instanceof Array) {
          copy = [];
          for (var i = 0, len = obj.length; i < len; i++) {
              copy[i] = this.deepCopy(obj[i]);
          }
          return copy;
      }

      // Handle Object
      if (obj instanceof Object) {
          copy = {};
          for (var attr in obj) {
              if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
          }
          return copy;
      }

      throw new Error("Unable to copy obj! Its type isn't supported.");
    },
    /**
     * 로그인
     * @create 
     * @author 정한슬
     * @description 로그인
    */
    async onSubmit (e) {
      this.show = true // 로딩
      let obj = this.deepCopy(this.item)
      if(obj.userSe === 'GNR1') obj.userSe = 'GNR'
      if(obj.userSe === 'GNR2') obj.userSe = 'GNR'
      const params = new URLSearchParams();// 한글 깨짐 때문에 사용
      params.append('id', obj.id);
      params.append('password', obj.password);
      params.append('userSe', obj.userSe);
      // console.log(obj.id);
      await this.$store
          .dispatch("LOGIN", params)
          .then((response) => {
            if (response.data.status === 'success') { 
              this.show = false // 로딩 제거
              if (this.status == true) { // 아이디 저장을 체크 하였을때
                this.setCookie("id", obj.id, 7); //쿠키이름을 id로 아이디입력필드값을 7일동안 저장
              } else { // 아이디 저장을 체크 하지 않았을때
                this.setCookie("id", obj.id, 0); //날짜를 0으로 저장하여 쿠키삭제
              }
              delete response.data.loginVO.password // 패스워드 관련 key 삭제
              delete response.data.loginVO.passwordCnsr
              delete response.data.loginVO.passwordHint
              sessionStorage.setItem('loginVO', JSON.stringify(response.data.loginVO))
              this.$store.dispatch("getUcsServer") // ucs 서버 정보 store에 저장
              this.$store.dispatch("getCmsServer") // cms 서버 정보 store에 저장
              this.redirect()
            } else {
              this.show = false // 로딩 제거
              swal(response.data.message)
              this.status = false // 아이디 저장 check false
              sessionStorage.removeItem('loginVO')
            }
          })
          .catch((e) => {
            this.show = false // 로딩 제거
            alert('서버와의 연결에 실패했습니다.')
            console.log(e)
          })
    },
    /**
     * 로그인 후 경로 설정
     * @create 
     * @author 정한슬
     * @description 로그인 후 경로 설정
    */
    redirect() { 
      const loginVO = JSON.parse(sessionStorage.getItem('loginVO')) // 세션스토리지에서 로그인 정보 get
      let uri = window.location.href.split('?');
      if (uri.length == 2) // returnurl 이 있을 때
      {
        if(this.$route.query.hasOwnProperty('returnPath')){
          // console.log(this.$route.query.returnPath)
          this.$router.push({path:this.$route.query.returnPath})
        }
        // do
      } else {
        let userSe = loginVO.userSe
        if(userSe === 'GNR'){
          this.$router.push('/dashboard/main')
        } 
        // if(userSe === 'ENT'){
        //   this.$router.push('/ecs/dashboard/main')
        // }
        if(userSe === 'USR'){
          this.$router.push('/dashboard/main')
        }
      }
      // 리다이렉트 처리
    },
    /**
     * 아이디 쿠키에 저장
     * @create 
     * @author 정한슬
     * @description 아이디 쿠키에 저장
    */
    setCookie(name, value, expiredays) { //쿠키 저장함수 
      let todayDate = new Date();
      todayDate.setDate(todayDate.getDate() + expiredays);
      document.cookie = name + "=" + escape(value) + "; path=/; expires=" + todayDate.toGMTString() + ";"
    },
    /**
     * 쿠키에 저장된 ID 불러오기
     * @create 
     * @author 정한슬
     * @description 쿠키에 저장된 ID 불러오기
    */
    getCookie(Name) { // 쿠키 불러오는 함수
      let search = Name + "=";
      let offset = ''
      let end = ''
      if (document.cookie.length > 0) { // if there are any cookies
        offset = document.cookie.indexOf(search);
        if (offset != -1) { // if cookie exists
            offset += search.length; // set index of beginning of value
            end = document.cookie.indexOf(";", offset); // set index of end of cookie value
            if (end == -1)
                end = document.cookie.length;
            return unescape(document.cookie.substring(offset, end));
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
